import React from 'react'
import Image from './../components/image'

export default (props) => (

<>

<h1>Über mich</h1>

<div className="columns page-section">
    <div className="column ueber-card-1-column">
        <div className="card vcard ueber-card-1 is-pulled-right">
            <div className="vcard-item vcard-item-image">
                <Image filename="wolfgang_outdoor_crop.jpg" alt="Foto Wolfgang Engelhardt" />
            </div>
            <div className="vcard-item vcard-item-content">
                
                <h1>Wolfgang Engelhardt</h1>
                <p className="small">
                    geboren am 21.10.1966<br />
                    in Gaildorf (Deutschland).<br />
                    Geschieden, 3 Kinder.
                </p>

            </div>
        </div>
        <div>

        <h2>Eigene Berufung finden</h2>
        <p>
            Das Finden der eigenen Berufung und „das in die Welt bringen“ der ureigenen Talente und Begabungen
            ist mir essentielles Bedürfniss, Antrieb und Vision in meiner Tätigkeit mit Menschen.
        </p>
        <h2>Maltherapie</h2>
        <p>
            Malen um der schönen Bilder wegen macht für mich keinen Sinn. Das was in der Tiefe bewegt, heraus
            will, sich zeigen will, dass gilt es auszudrücken. Diese gemalten Bilder heilen und diese Bilder
            sind letztendlich auch interessant und in der Lage dein Leben zu verändern. Das Erlebnis durch die
            lösungsorientierte Maltherapie ein Trauma aufzulösen, hat mich zutiefst für diese Technik
            eingenommen.
        </p>
        </div>
    </div>
</div>

<div className="page-section no-line" style={{marginTop: 0, paddingTop: "1rem"}}>

    <div className="card ueber-einsicht-card has-text-centered">
        <h3>Einsicht in den Wandlungsprozess des Lebens</h3>
        <p>
            Der Ausdruck innerer, meist unbewußter Werte und Haltungen durch das Malen von Bildern, gehörte
            schon in meiner frühen Kindheit zu meinen Wesenszügen; genauso wie das Spielerische, Körperliche und
            Leistungsbezogene über allerlei Sportarten zum Ausdruck kommen konnte.
        </p>
    </div>

</div>

<div className="page-section no-line">

    <h2>Qualifikation</h2>

    <div className="columns">
        <div className="column is-half list-column">
            <ul>
                <li>Mal- und Gestaltungstherapeut (MGT)</li>
                <li>Lebens- und Sozialberater</li>
                <li>Systemisch kunsttherapeutischer Supervisor</li>
                <li>Dipl. Verwaltungswirt (FH)</li>
            </ul>
        </div>
        <div className="column is-half  list-column">
            <ul>
                <li>Div. Weiterbildungen in den Bereichen: Kommunikation, Kontemplation, Rituale, Kinder- und
                    Jugendgesundheit</li>
                <li>Lehrsupervisor am Institut für Mal- und Gestaltungstherapie Erwin Bakowski in Wien</li>
            </ul>
        </div>
    </div>
</div>


<div className="columns page-section innen-vcard-section no-line">
    <div className="column is-half">
        <div className="card vcard" style={{maxHeight:"400px"}}>
            <div className="vcard-item vcard-item-image">
                <Image filename="innen.jpg" alt="Gemälde Wolfgang Engelhardt Innenansicht"/>
            </div>
            <div className="vcard-item vcard-item-content ">
                
                <h1>Wolfgang Engelhardt</h1>
                <p>
                    <i>"Innenansicht"</i>
                </p>

            </div>
        </div>
    </div>
    <div className="column is-half">

        <h2>Beschäftigung</h2>
        <p>
            Betreuer und Arbeitsanleiter in einer Arbeitstherapieeinrichtung für psychisch erkrankte Menschen.
            Supervision und Maltherapie in freier Praxis. Lehrsupervisor am Institut für Mal- und Gestaltungstherapie in
            Wien.
        </p>
        <ul className="yearlist">
            <li>
                <span class="year">2014 – 2018</span>
                Maltherapie in einem Kinderdorf für Kinder mit Entwicklungsstörungen, ADS/ADHS, Minderbegabung und
                autistische Störung
            </li>
            <li>
                <span class="year">2003 – laufend</span>
                Betreuer und Arbeitsanleiter in einer Arbeitstherapieeinrichtung für psychisch erkrankte Menschen
            </li>
            <li>
                <span class="year">2010 - 2012</span>
                Abteilungsleiter und Trainer im Bereich Clearing und Arbeitserprobung für Langzeitarbeitslose
            </li>
            <li>
                <span class="year">2001 – 2003</span>
                Sabbatzeit (Leben in Gemeinschaft, Alexandertechnik, Ritualarbeit, Spiritualität)
            </li>
            <li>
                <span class="year">1993 – 2001</span>
                stellv. Leiter der Finanzverwaltung und kaufmännischer Leiter der Eigenbetriebe in einer 11-tausend
                Einwohnergemeinde in Deutschland
            </li>
        </ul>
    </div>
</div>


<div className="columns page-section no-line" style={{marginTop: "-1rem", paddingTop: "0"}}>
    <div className="column">
        <h2>Prägende Ereignisse</h2>
        <p>
            Ehemals Extremsport (Triathlon Ironman); Eingehende spirituelle Auseinandersetzung nach umbrechendem
            Glaubenserlebnis; Intensive Auseinandersetzung mit meinem Mann-Sein (Männerinitiation, Visionssuche,
            Naturrituale, Männergruppen); Ehe; Scheidung.
        </p>
    </div>
</div>

</>

)
